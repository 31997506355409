export const adminAdEditPath = (adId: string | number): string => `/admin/ads/${adId}/edit`
export const adminUsersPath = (): string => '/admin/users'
export const claimYourPagePath = (): string => '/suppliers/onboarding/search'
export const contentVisits = (): string => '/content_visits'
export const createReviewPath = (supplierId: string | number): string => `/suppliers/${supplierId}/review`
export const createReviewWithRatingPath = (supplierId: string | number, rating: string | number): string => `${createReviewPath(supplierId)}?r=${rating}`
export const createSupplierOnboardingPath = (): string => '/suppliers/onboarding/suppliers'
export const editOrganizationPath = (organizationId: string | number): string => `/admin/organizations/${organizationId}/edit`
export const editReviewPath = (reviewId: string | number): string => `/reviews/${reviewId}/edit`
export const editSupplierOnboarding = (supplierId: string | number): string => `/suppliers/${supplierId}/onboarding/edit_supplier`
export const editSupplierOnboardingPath = (supplierId: string | number): string => `/suppliers/${supplierId}/onboarding/edit_supplier`
export const newConversationPath = (reviewId: string | number): string => `/reviews/${reviewId}/conversations/new`
export const newEntryPath = (entryableId: string | number, entryableType: string): string => `/entries/new?entry[entryable_id]=${entryableId}&entry[entryable_type]=${entryableType}`
export const reviewMicroUpdatePath = (reviewId: string | number): string => `/reviews/${reviewId}/micro_update`
export const reviewPath = (): string => '/review'
export const reviewWithRatingPath = (supplierId: string | number, rating: string | number): string => `${reviewPath()}?supplier_id=${supplierId}&r=${rating}`
export const reviewWithSupplierPath = (supplierId: string | number): string => `${reviewPath()}?supplier_id=${supplierId}`
export const supplierCommoditiesPath = (supplierId: string | number): string => `/suppliers/${supplierId}/commodities`
export const supplierLandingPath = (): string => '/suppliers'
export const supplierPagePath = (supplierId: string | number, opts: { anchor?: string } = {}): string => `/suppliers/${supplierId}${typeof opts.anchor === 'string' ? '#' + opts.anchor : ''}`
export const supplierSearchPath = (): string => '/suppliers_search'
export const businessInfoPath = (slug: string): string => `/suppliers/${slug}/onboarding/business_info`
export const businessInfoUpdatePath = (slug: string): string => `${businessInfoPath(slug)}#updates`
export const businessInfoServiceAreaPath = (slug: string): string => `${businessInfoPath(slug)}#service-areas`
export const businessInfoCommodityPath = (slug: string): string => `${businessInfoPath(slug)}#commodity-codes`
export const businessInfoReviewCollectionPath = (slug: string): string => `${businessInfoPath(slug)}#review-collection`
export const canaryPath = (): string => '/canary'
export const canaryContractPath = (contractSlug: string): string => `${canaryPath()}/contracts/${contractSlug}`
export const canaryForSuppliersContractPath = (contractSlug: string): string => `${canaryPath()}/for_suppliers/contracts/${contractSlug}`
export const canaryForSuppliersShowPath = (contractVehicleSlug: string): string => `/canary_for_suppliers/contract_vehicles/${contractVehicleSlug}`
